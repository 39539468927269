import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

export function Loading({data}) {
    LoadingOverlay.propTypes = undefined;

    const [loading, setLoading] = useState({...data});

    useEffect(() => {
        if (!data.loading) setTimeout(() => setLoading(prev => ({ loading: false, text: ""})), 5);
        else setLoading({...data});
    }, [data, data.loading])

    return (
        <LoadingOverlay active={loading.loading} spinner fadeSpeed={300} className="loader" text={loading.text}>
            {loading.loading && <div className="make-big"></div>}
        </LoadingOverlay>
        )
}