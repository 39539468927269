import { useState } from "react";
import { Link } from "react-router-dom";
import apiFetch from "../../functions/apiFetch";
import { setLocalStorage } from "../../functions/getParamsAndCache";
import { Settings } from "../Settings/Settings";


export function Login() {

    const [errors, setErrors] = useState(false);
    function handleClick(e) {
        e.preventDefault();
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;
        apiFetch("login-user?username=" + username + "&hash=" + password, 0)
        .then(data => {
            if(data.message === "User Not Found") setErrors(prev => prev = "The username or password you entered is not valid");
            else if (data.id) {
                window.localStorage.setItem("user", JSON.stringify(data));
                setLocalStorage("notify-data", { message : "Logged In Successfully!", code : 200}, true);
                setLocalStorage("redirect", "/my-pictures", true, false);
            }
        });
    }

    return (
        <div>
            <div className="dedication">
                <h2>לעילוי נשמת הרב ברוך מרדכי יהודה בן הרב ברוך יהודה זצ"ל</h2>
            </div>
                <div  className="login-form">
                    <h2>Login</h2>
                    <div className="form-sect">
                        <label>username</label>
                        <input id="username" type="text" />
                    </div>
                    
                    <div className="form-sect">
                        <label>password</label>
                        <input id="password" type="password" />
                    </div>

                    <p className="form-p form-err">{errors && errors}</p>
                    <button onClick={handleClick} className="form-submit" type="button">Login</button>
                    <p className="form-p">forgot password? <Link to={"/reset-password"}>click here</Link></p> 
                    <br />
                    <hr className="form-hr" />
                    <br />
                    <div className="form-signup">
                        <p className="form-p">don't have an account?</p> 
                        <h4 className="form-p">sign up now to create an account!</h4>
                        
                        <Link to={"/sign-up"} component={<Settings />} >
                            <button className="form-submit" >Sign Up</button>
                        </Link>
                        
                    </div>
                </div>
        </div>
    )
}