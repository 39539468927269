import { useEffect, useState, useCallback } from "react";
import apiFetch from "../../functions/apiFetch";
import { Sort } from "./Sort";
import { GetPin } from "./GetPin";
import { GlassMagnifier } from "react-image-magnifiers";
import { getLocalStorage, getSearchParam, getUrl, getUser, setLocalStorage } from "../../functions/getParamsAndCache";
import { Image } from "./Image";
import { DaySection } from "./DaySection";
import { useRef } from "react";
import { fetchThumbnail, whileWaiter } from "../../functions/fetchImages";
import { Loading } from "../Animations/Loading";

export function MyPictures({isPublic}) {
    const routeId = getSearchParam("id");
    const folder = getSearchParam("folder");
    const email = getSearchParam("email");
    const tempUser = getLocalStorage("tmpuser");
    let user = getUser();
    const [imgs, setImages] = useState([]);
    const [loading, setLoading] = useState({isLoading: false, loading: null});
    const [devices, setDevices] = useState([]);
    const [num, setNum] = useState(0);
    const [info, setInfo] = useState({});
    const [bigSrc, setBigSrc] = useState(null);
    const [finish, setFinish] = useState(false);
    const [sort, setSort] = useState(true);
    const [flagged, setFlagged] = useState(false);
    const [device, setDevice] = useState("");
    const timer = useRef();
    let p = "props";
    let h = "headers";

    const getImages = useCallback(async () => {
        const startTime = Date.now();
        // if the user has loaded all available pictures or the user has not yet entered the security pin
        if (finish || (isPublic === false && !tempUser)) return;

        let imgArr = [];
        let imageCount = num;
        const sortOptions = "&reverse=" + sort + "&flagged=" + flagged + (device ? "&device=" + device : "");
        
        if(routeId) {
            user.id = routeId;
            user.loggonToken = isPublic ? folder : tempUser;
        }
        
        // if for some reaon the system doesn't have the users system id yet then redirect to home page
        if(!user?.id) {
            setLocalStorage("notify-data", { message: "User not authenticated", code: 401}, true);
            return setLocalStorage("redirect", "/login", true, false);
        }

        !devices.length && apiFetch("get-devices?id=" + user.id + "&logged=" + user.loggonToken, 0)
        .then(({devices}) => setDevices(devices.map(dvc => ({ deviceName: dvc.deviceName, id: dvc.id }))));

        return apiFetch("image-count?id=" + user.id + "&logged=" + user.loggonToken, 0)
        .then(async (serverCount) => {
            if(num >= serverCount) return setFinish(true);
            
            let loopCount = 0;
            while(fetchThumbnail(user, imageCount++, loopCount, sortOptions, setFinish, imgArr, email))
                await whileWaiter(loopCount++ >= 30);
            
            imgArr = imgArr.map(({ blob, headers }) => {
                return blob ? <Image key={headers.imageId} {...{headers, imageObjectURL: URL.createObjectURL(blob)}} /> : null;
            }).filter(i => i && i[p][h].sysName)
                
            if (imgArr.length !== 30) setFinish(true);
            setNum(imageCount);

            setImages(prev => {
                if(flagged) return [...prev, ...imgArr].sort((a, b) => b[p][h].imageId - a[p][h].imageId).sort((a, b) => b[p][h].flagged - a[p][h].flagged);
                else if(device) return [...prev, ...imgArr].sort((a, b) => b[p][h].imageId - a[p][h].imageId) // idk what this is for, delete after using 2 devices and still works .sort((a, b) => a[p][h].device === device );
                else return [...prev, ...imgArr].sort((a, b) => !sort ? (a[p][h].imageId - b[p][h].imageId) : (b[p][h].imageId - a[p][h].imageId));
            })
            console.log(Date.now() - startTime);
        })
    }, [device, devices.length, finish, flagged, folder, h, isPublic, num, p, routeId, sort, tempUser, user])
    
    function handleView(id) {
        setLoading({isLoading: true, loading: "image"});
        user = JSON.parse(window.localStorage.getItem("user")) || {};
        if(routeId) {
            user.id = routeId;
            user.loggonToken = isPublic ? folder : tempUser;
        }
        fetch(getUrl("get-my-image?id=" + user.id + "&logged=" + user.loggonToken + "&imageId=" + id + (email ? "&email=" + email : "")))
        .then(response => {
            if(response.status !== 200) {
                return;
            }
            return response.blob()
        })
        .then(imageBlob => {
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setBigSrc(imageObjectURL);
        })
        .then(() => setLoading({isLoading: false, loading: null}));
    }

    function handleInfo(headers) {
        if(info && info.sysName === headers.sysName) return setInfo({});
        else setInfo(headers);
    }

    async function handleSort() {
        setNum(0);
        setFinish(false);
        setImages([]);
        setDevice("");
        setFlagged(false);
        setSort(!sort);
    }

    async function handleFlagged() {
        setNum(0);
        setFinish(false);
        setImages([]);
        setSort(false);
        setDevice("");
        setFlagged(!flagged);
    }

    async function handleDevice(dev) {
        setNum(0);
        setFinish(false);
        setImages([]);
        setFlagged(false);
        setSort(false);
        setDevice(dev.id);
    }

    useEffect(() => setLoading({isLoading: true, loading: "images"}), [sort, flagged, device])

    useEffect(() => {imgs.length !== 0 && setLoading({isLoading: false, loading: null})}, [imgs])

    useEffect(() => {
        if(loading.isLoading && loading.loading === "images" && !loading.ranAlready) {
            setLoading(prev => ({ranAlready : true, ...prev}));
            getImages();
            clearInterval(timer.current);
            timer.current = setInterval(() => setLoading({isLoading: false, loading: null}), 13000);
        }
    }, [loading, getImages])
    
    return (isPublic === false && !tempUser ? <GetPin /> :  
        <div className="pictures-view-container">
            <Loading data={{loading : loading.isLoading, text : 'Loading your pictures...'}} />
            <Sort devices={devices} handleSort={handleSort} handleFlagged={handleFlagged} handleDevice={handleDevice} />
            {bigSrc && <div className="big-container" onClick={(e) => e.detail > 1 ? setBigSrc(null) : null}>
                <div className="close">
                    <h1 className="close-aro">→</h1>
                        <h2 onClick={() => setBigSrc(null)}>2X Click To Close</h2>
                    <h1 className="close-aro">←</h1>
                </div>
                {/* make this only happen in pc with mouse */}
                {window.matchMedia("(hover: none)").matches ? <img alt="big-img" className="big-image" src={bigSrc} /> 
                : <GlassMagnifier cursorStyle="move" imageSrc={bigSrc} imageAlt="big-image" /> }
            </div>}
            <div className="header-container">
                <h1>Your Images</h1>
            </div>
            {/* add loading thing when loading and make work */}
            {[...new Set(imgs.map(img => img[p][h].day))].map((day) =>  
                <DaySection key={day} {...{day, handleView, info, handleInfo, handleDelete: () => true, routeId}}>
                    {imgs.filter(img => img[p][h].day === day)}
                </DaySection>
            )}
            <div className="btn-container">
                <button 
                className="load-btn" 
                disabled={finish || loading.isLoading}
                onClick={() => loading.isLoading ? null : setLoading({isLoading: true, loading: "images"})}>
                    {loading.isLoading ? "Loading" : "Load More"}
                </button>
            </div>
        </div>
    )
}

/* 
function handleDelete(id) {
    delete functionality disabled

    setLoading(true);
    user = getUser();
    if(routeId) {
        user.id = routeId;
        user.loggonToken = isPublic ? folder : tempUser;
    }
    apiFetch("delete-image?id=" + user.id + "&logged=" + user.loggonToken + "&imageId=" + id, 3)
    .then(data => {
        if(data.message === "Deleted Successfully") {
            setImages(prev => {
                return prev.filter(img => img.props.imageid !== id);
            });
            setLoading(false);
        }
    });
}
*/