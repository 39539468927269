import "../../App.css";
import {AiOutlineHome, AiOutlinePicture, AiOutlinePhone, AiOutlineSetting} from "react-icons/ai"
import {FaSpider} from "react-icons/fa"
import {RiAccountBoxLine} from "react-icons/ri"
import { Link } from "react-router-dom";
import { isLoggedIn } from "../../functions/getParamsAndCache";
import { useEffect } from "react";
import { useState } from "react";

export function NavBar() {

  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  useEffect(() => {
        
    const checkUserLogged = () => setLoggedIn(isLoggedIn());
  
    window.addEventListener('storage', checkUserLogged)
  
    return () => {
      window.removeEventListener('storage', checkUserLogged)
    }
  }, [])

  return <div className="topnav">
    <Link to={"/"} >
      <span className="nav-icon"><AiOutlineHome /></span> <span className="nav-text">Home</span>
    </Link>

    {loggedIn && <Link to="my-pictures" >
      <span className="nav-icon"><AiOutlinePicture /></span> <span className="nav-text">My Pictures</span>
    </Link>}

    <Link to={"/contact-us"} >
      <span className="nav-icon"><AiOutlinePhone /></span> <span className="nav-text">Contact Us</span>
    </Link>

    <Link to={"/spider"} >
      <span className="nav-icon"><FaSpider /></span> <span className="nav-text">Spider</span>
    </Link>

    { loggedIn ? 
    <Link to={"/settings"} className="settings-nav">
      <span className="nav-icon"><AiOutlineSetting /></span> <span className="nav-text">Settings</span>
    </Link> : 
    
    <Link to={"/login"} className="settings-nav" >
      <span className="nav-icon"><RiAccountBoxLine /></span> <span className="nav-text">Login</span>
    </Link>}
  </div>
}