import { useState } from "react";
import Expand from "react-expand-animated";
import { ImageCard } from "./ImageCard";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";

export function DaySection({day, handleView, info, handleInfo, handleDelete, routeId, children}) {
    const [open, setOpen] = useState(true);
    return (<>
        <hr className="save-hr" />
        <h2>{day}<span className="minify-section" onClick={() => setOpen(!open)}>{open ? <FiMinimize2 /> : <FiMaximize2 />}</span></h2>
        <Expand
            className={open ? "expand-thing" : ""}
            open={open}
            duration={400}>
            {<div className="img-container">
                {children.map((img, idx) => <ImageCard key={idx} {...{img, handleView, info, handleInfo, handleDelete, routeId}} />)}
            </div>}
        </Expand>
    </>)
}