import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from '../../functions/getParamsAndCache';

export function Redirect(){
    const navigate = useNavigate();

    useEffect(() => {
        function checkUserData() {
          let msg = localStorage.getItem('redirect');

          console.log(msg)
          if(msg) {
            
            setLocalStorage("redirect", "", false, false);
            navigate(msg)
          }  
        }
      
        window.addEventListener('storage', checkUserData)
      
        return () => {
          window.removeEventListener('storage', checkUserData)
        }
      }, [navigate])
  return null;
}