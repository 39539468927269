import { clearLocalStorage, getUrl, setLocalStorage } from "./getParamsAndCache";

const MTD = ["GET", "POST", "PUT", "DELETE", "PATCH"];

async function apiFetch(params, method, headers = {}, body = null) {
    return await fetch(getUrl(params), {
        method: MTD[method],
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        body: body,
    }) 
    .then(async data => {
        var readData = await data.json();
        // handle errors here
        if(data.status === 401) {
            clearLocalStorage();
            return setLocalStorage("redirect", "/login", true, false);
        }
        if (readData.message) 
            setLocalStorage("notify-data", { message : readData.message, code : data.status}, true);
        if (readData.errors)
            handleValidationErrors(readData.errors);
        return readData;
    })
    .then((data) => {
        console.log(data);
        return data;
    })
    .catch((e) => console.error(e))
}

function handleValidationErrors(errors) {
    Object.keys(errors).forEach(key => 
        setLocalStorage("notify-data", { header: key, message: errors[key].join("$$$"), code : 300}, true))
}

export default apiFetch;
