import { useEffect } from "react";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { validateEmail } from "../../functions/validations";

export function EmailToSend({emailToSend, setSendEmail, isEditPage}) {
    
    function changeEtsValue(newVal, idx) {
        var re = new RegExp("[a-zA-Z0-9+@_.-]");
        if (re.test(newVal.substr(-1)) || newVal.length < 2)
            setSendEmail(old => old.map((email, i) => idx === i ? newVal : email));
    }

    useEffect(() => {
        if (emailToSend.length === 0) setSendEmail([""]);
    }, [emailToSend, setSendEmail])

    return (
        <div className="settings-section">
            <h4 className="edit-h4">{isEditPage ? "Edit" : "Set"} Emails To Send Link ~ ( up to 5 email addresses )</h4>
            <p className="edit-h4">( make sure to press + before saving so the email is added)</p>
            {emailToSend.map((ets, idx, arr) => {
                let target = <input className="edit-input" type="text" onChange={(e) => changeEtsValue(e.target.value, idx)} value={ets} />;
                return (
                    <span key={idx} className="addEmailSpan">
                        {target}
                        {arr.length -1 !== idx ? 
                        <FiMinusSquare className="addEmailSpanAdd" 
                            onClick={(e) => setSendEmail(old => old.filter(email => email !== ets))} /> : 
                        <FiPlusSquare className="addEmailSpanAdd" 
                                      onClick={(e) => emailToSend.length < 6 && setSendEmail(old => validateEmail(target.props.value) ? [...old, ""] : old)} 
                        />}
                    </span>
                )
            }
            )}
            
            <hr className="edit-hr" />
        </div>
    )
}