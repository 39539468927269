import { useState } from "react";
import apiFetch from "../../functions/apiFetch";
import { getSearchParam, setLocalStorage } from "../../functions/getParamsAndCache";
import { validateEmail } from "../../functions/validations";
import { EditUserInfo } from "../Settings/EditUserInfo";
import { EmailToSend } from "../Settings/EmailToSend";

export function SignupInfoCollection() {
    
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [emailToSend, setSendEmail] = useState([""]);
    const [pinRequired, setPinReq] = useState(true);
    const [pin, setPin] = useState("");

    function handleNext() {
        const password = document.querySelector("#new-pass").value;
        const password_c = document.querySelector("#confirm-pass").value;
        if (password !== password_c) return setLocalStorage("notify-data", { message : "Passwords don't match", code : 300}, true);
        if (password.length < 8) return setLocalStorage("notify-data", { message : "Passwords has to be 8 charecters long", code : 300}, true);
        if (username.length < 8) return setLocalStorage("notify-data", { message : "Username has to be 8 charecters long", code : 300}, true);
        if (!validateEmail(email)) return setLocalStorage("notify-data", { message : "Please enter a valid email", code : 300}, true);
        if (emailToSend.length < 2) return setLocalStorage("notify-data", { message : "Please enter at least 1 email to send link", code : 300}, true);
        if (pinRequired && pin.length < 4) return setLocalStorage("notify-data", { message : "The security pin has to be at least 4 digits", code : 300}, true);

        const req = { email, username, emailToSend: emailToSend.slice(0, -1).join(";"), pinRequired, pin, password };
        
        apiFetch(`signup`, 1, {}, JSON.stringify(req))
        .then((res) => {
            if(res.id) setLocalStorage("redirect", "/payment?devices=" + getSearchParam("devices"), true, false);
        });
    }

    return <div>
        <h1 className="signup-h1">Create Account</h1>
        <EditUserInfo {...{email, setEmail, username, setUsername, isEditPage : false}} />

        <div className="edit-settings">
            <h3 className="edit-h3">Set User Settings</h3>
            <div className="edit-user">

                <EmailToSend {...{emailToSend, setSendEmail, isEditPage : false}} />
                
                <div className="settings-section">
                    <h4 className="edit-h4">Add Security Pin To Link</h4>
                    <input className="edit-input req-input" type="checkbox" onChange={(e) => setPinReq(e.target.checked)} checked={pinRequired} />
                    <hr className="edit-hr" />
                </div>
                <div className="settings-section">
                    <h4 className="edit-h4">Set Security Pin</h4>
                    <input className="edit-input pin-input" type="text" onChange={(e) => setPin(e.target.value)} value={pin} />
                </div>
            </div>
            <hr className="save-hr" />
                <span className="save-container">
                    <button className="save-btn" onClick={handleNext} >Next</button>
                </span>
        </div>
    </div>
}