export function numberFormat(num, interval = 3) {
    const digits = String(num).split('').reverse();
    const output = [];

    digits.forEach(function (digit, index, digits) {
        output.push(digit);
        if ((index + 1) % interval === 0 && (index + 1) < digits.length) {
            output.push(',');
        }
    });

    return output.reverse().join('');
}


            
export function capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }