import { AiOutlineRight } from "react-icons/ai"
import { Link } from "react-router-dom";

export function Home() {
    return <div className="homePage">
        <div className="homeChild">
            <div className="homeHead">
                <p className="bsd">בס״ד</p>
                <h1 className="home-h1">|The Prestige Filter|</h1>
                <h3 className="home-h3">A Prestigious Filtering Solution</h3>
            </div>
            <hr className="home-hr" />
            <div className="homeBody">
                <img src="camera.png" alt="cam" className="cameraImg"/>
                <h2 className="home-body-h2">We believe in you!</h2>
                <h2 className="home-body-h2">Thats why :</h2>
                <p className="home-body-p">Our mission is to keep you protected from todays internet without needing to filter your content</p>

                <h3 className="home-body-h3">Sign up now to join our prestigiously filtered community</h3>
                <Link to={"/signup"} className="signup-btn">
                    <span>Signup<AiOutlineRight className="signup-right-caret" /></span>
                </Link>
            </div>
        </div>
    </div>;
}