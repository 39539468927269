import { useState } from "react";
import apiFetch from "../../functions/apiFetch";
import { setLocalStorage } from "../../functions/getParamsAndCache";

export function ResetPassword() {
    
    const [errors, setErrors] = useState(false);

    function handleSubmit(e) {
        
        e.preventDefault();
        const email = document.getElementById("reset-email").value;
        apiFetch("reset-pass?email=" + email, 0)
        .then(data => {
            if(data.message === "Account Not Found") setErrors(prev => prev = "We couldn't find an account with this email address.");
            else {
                setLocalStorage("redirect", "/login", true, false)
            }
        });
    }

    return (
        <div className="login-form">
            <h1 className="signup-h2">Reset Password</h1>
            <br />
            <h4 className="form-p">Please enter your email address below. You will receive a link to reset your password.</h4>
            <br />
            <div className="form-sect">
                <input id="reset-email" type="text" placeholder=" Email address"/>
                <button className="form-submit" onClick={handleSubmit}>submit</button>
            </div>       
            <p className="form-p form-err">{errors && errors}</p>
        </div>
        )
}