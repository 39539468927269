import { getHeaders, getUrl } from "./getParamsAndCache";

export const fetchThumbnail = (user, imgNumber, loopCount, sortOptions, setFinish, responseArray, email = "") => {
    if(responseArray.length >= 30) return false;
    if(loopCount >= 30) return true;
    fetch(getUrl("get-my-images?id=" + user.id + "&logged=" + user.loggonToken + "&num=" + (imgNumber) + sortOptions + (email ? "&email=" + email : "")))
    .then(async response => {
        if(response.status === 404) 
            setFinish(true);
        if(response.status !== 200) 
            return responseArray.push({});
        else return responseArray.push({blob: await response.blob(), headers: getHeaders(response.headers)});
    })
    return true;
}

export const whileWaiter = async (condition) => 
    condition && await new Promise(resolve => setTimeout(resolve, 100))

