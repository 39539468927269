import Expand from "react-expand-animated";
import { BsFlagFill, BsEyeFill } from "react-icons/bs";
import { FaRegTrashAlt, FaExpandArrowsAlt, FaInfoCircle, FaRegEye } from "react-icons/fa"

export function ImageCard({img, handleView, info, handleInfo, handleDelete, routeId}) {
    
    const {imageId, disName, flag, sysName, viewers} = img.props.headers;
    return (
        <div key={img.props.imgcount} className="img-card">
            <div className="img-tag-container" onClick={(e) => handleView(imageId)}>{img}</div>
            <div className="img-info">
                <h3 className="title" onClick={() => handleView(imageId)}>{disName}{Number(flag) !== 0 ? <span className="flag"><BsFlagFill /></span> : ""}</h3>
                    <Expand
                        className={info.sysName === sysName ? "expand-thing" : ""}
                        open={info.sysName === sysName}
                        duration={400}>
                    {<div>
                        <h4>Date Created : {info.created || ""}</h4>
                        <h4>File Size : {info.size || ""}</h4>
                        <h4>Device : {info.device || ""}</h4>
                        {!routeId && <table className="views-table">
                            <thead>
                                <tr>
                                <th>Email</th>
                                <th>Opened</th>
                                <th>Viewed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {viewers.map((viewer, index) => (
                                <tr key={index}>
                                    <td>{viewer.Username}</td>
                                    <td>{viewer.deepView ? 'Yes' : 'No'}</td>
                                    <td>{viewer.ViewedDate}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>}
                        {Number(info.flag) !== 0 && <h4>Flagged Level : {info.flag}</h4>}
                    </div>}
                </Expand>     
            </div>
            <div className="img-controls">
                <span onClick={() => handleView(imageId)} className="control"><FaExpandArrowsAlt /></span>
                {!routeId ? <span onClick={() => handleDelete(imageId)} className="control"><FaRegTrashAlt /></span> :
                    <span className="control">Flag</span>}
                <span onClick={() => handleInfo(img.props.headers)} className="control"><FaInfoCircle /></span>
                {!routeId && <span onClick={() => handleDelete(imageId)} className="control"><BsEyeFill className="eye-icon"/> ({viewers.length}/{viewers.filter(v => v.deepView).length})</span>}
            </div>
        </div>
    )
}