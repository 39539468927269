
export const ContactUs = () => {


    return (
        <div className="contact-us" >
            <h2>Contact Us</h2>
        </div>
    )
}
  

