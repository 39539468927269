import { useState } from "react";
import apiFetch from "../../functions/apiFetch";
import { getSearchParam, setLocalStorage } from "../../functions/getParamsAndCache";

export function ResetPasswordAction() {
    
    const [errors, setErrors] = useState(false);

    function handleSubmit(e) {
        
        e.preventDefault();
        const pass = document.getElementById("reset-pass").value;
        const pass_c = document.getElementById("reset-pass-c").value;
        if (pass !== pass_c) return setErrors(prev => prev = "The passwords don't match, please make sure that the confirm password matches.");
        const id = getSearchParam("id");
        const key = getSearchParam("key");
        apiFetch(`reset-pass?id=${id}&key=${key}&newPass=${pass || "l"}`, 4)
        .then(data => {
            if(data.message === "Password updated successfully!")  setLocalStorage("redirect", "/login", true, false);
        });
    }

    return (
        <div className="login-form">
            <h1 className="signup-h2">Reset Password</h1>
            <br />
            <h4 className="form-p">Please enter a new password for your account below.</h4>
            <br />
            <div className="form-sect">
                <input id="reset-pass" type="password" placeholder=" New Password"/>
                <br />
                <input id="reset-pass-c" type="password" placeholder=" Confirm Password"/>
                <button className="form-submit" onClick={handleSubmit}>submit</button>
            </div> 
                  
            <p className="form-p form-err">{errors && errors}</p>
        </div>
        )
}