import moment from "moment";
import { numberFormat } from "../../functions/numberFormat";

export function UserInfpTable({data}) {
    return (
        <div className="edit-settings">
            <h3 className="edit-h3">Additional Information</h3>
            <div className="table-section">
                <table className="table table-striped table-dark">
                    <thead>
                        <tr className="first-tr">
                            <td>#</td>
                            <td>Data</td>
                            <td>Value</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td><td>Last Email Send :</td><td>{moment(data.lastSend).format('LLLL')}</td>
                        </tr>
                        <tr>
                            <td>2</td><td>Signup Date :</td><td>{moment(data.signupDate).format('LLLL')}</td>
                        </tr>
                        <tr>
                            <td>3</td><td>Last Upload :</td><td>{moment(data.lastUpload).fromNow()}</td>
                        </tr>
                        <tr>
                            <td>4</td><td>Total Images Uploaded :</td><td>{numberFormat(data.imageCount)}</td>
                        </tr>
                        <tr>
                            <td>5</td><td>Total Images In System :</td><td>{numberFormat(data.currentImageCount)}</td>
                        </tr>
                        <tr>
                            <td>6</td><td>Total Images Deleted Today :</td><td>{data.deleteCount} out of 0 - ( not supported )</td>
                        </tr>
                        <tr>
                            <td>7</td><td>Connected Devices :</td><td>{data.devices.map(dvc => dvc.deviceName).join(" ~ ")}</td>
                        </tr>
                        <tr>
                            <td>8</td><td>Pin Required :</td><td>{data.pinRequired ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td>9</td><td>Pin :</td><td>{data.pin}</td>
                        </tr>
                        <tr>
                            <td>10</td><td>Link :</td><td className="anchor">{data.link}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}