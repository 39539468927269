import { useState } from "react"

export function Sort({handleSort, handleFlagged, handleDevice, devices}) {
    const [open, setOpen] = useState(false);
    return <div className={open ? "sort-open sort-container" : "sort-container"}>
        <div onClick={() => setOpen(!open)} className="sort-btn">
            <h1>&nbsp; Sort &nbsp;<span className="arrow-btn">&#8597;</span>&nbsp;</h1>
        </div>
        <div className="sort-content">
            <h4 onClick={() => {setOpen(false); handleSort()}} className="sort-option">Sort by name</h4>
            <h4 onClick={() => {setOpen(false); handleFlagged()}} className="sort-option">Sort by flagged</h4>
            <div className="devices-container">
                {devices.length > 1 && <h4 className="sort-option">Sort by device &#9660;</h4>}
                {devices.map((dev, i) => <p key={dev.id} onClick={() => {setOpen(false); handleDevice(dev)}} className="sort-option sort-one">{i+1}: {dev.deviceName}</p>)}
            </div>
        </div>
    </div>
}