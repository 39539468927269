export function getUser() {
    return window.localStorage.getItem("user") !== "undefined" ? JSON.parse(window.localStorage.getItem("user")) || {} : {};
}

export function isLoggedIn() {
    return window.localStorage.getItem("user") === "undefined" || !!JSON.parse(window.localStorage.getItem("user"));
}

export function getLocalStorage(item) {
    return window.localStorage.getItem(item);
}

export function setLocalStorage(name, data, sendEvent = false, stringify = true) {
    stringify ? window.localStorage.setItem(name, JSON.stringify(data)) : window.localStorage.setItem(name, data);
    if (sendEvent) window.dispatchEvent( new Event('storage') );
    return;
}

export function getSearchParam(param) {
    return new URLSearchParams(document.location.search).get(param);
}

export function clearLocalStorage() {
    return localStorage.clear();
}

export function getUrl(params) {
    const url = document.location.origin;
    return url.includes("localhost:3000") ? "http://prestigefilter.com/api/v1/" + params : url + "/api/v1/" + params;
}

export function getHeaders(headers) {
    let heads = {};
    heads.sysName = headers.get("systemn-name");
    heads.disName = headers.get("display-name");
    heads.created = headers.get("created-at");
    heads.day = headers.get("created-day");
    heads.flag = headers.get("flagged-level");
    heads.size = headers.get("size");
    heads.format = headers.get("format");
    heads.path = headers.get("full-path");
    heads.imgNumber = headers.get("image-number");
    heads.imageId = headers.get("image-id");
    heads.device = headers.get("device");
    heads.viewers = JSON.parse(headers.get("viewers"));
    return heads;
}

