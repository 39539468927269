export function validatePassword(req) {
    const newPass = document.querySelector("#new-pass").value;
    const oldPass = document.querySelector("#old-pass").value;
    if (!req.password) return (req.password = "<null_password>") && null;
    if (newPass === document.querySelector("#confirm-pass").value && newPass.length > 7 && oldPass.length > 7) 
        return `&old=${oldPass}`;
    delete req.password; 
    return "";
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };