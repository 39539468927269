import { useState } from "react";
import apiFetch from "../../functions/apiFetch";
import { validatePassword } from "../../functions/validations";
import { UserInfpTable } from "./UserInfoTable";
import { Loading } from "../Animations/Loading";
import { EmailToSend } from "./EmailToSend";
import { EditUserInfo } from "./EditUserInfo";
import { clearLocalStorage, getUser, setLocalStorage } from "../../functions/getParamsAndCache";
import {RiLogoutBoxLine} from "react-icons/ri"


export function Settings () {
    const [user] = useState(getUser());
    const [data, setData] = useState({});
    const [email, setEmail] = useState({});
    const [username, setUsername] = useState({});
    const [emailToSend, setSendEmail] = useState([]);
    const [pinRequired, setPinReq] = useState({});
    const [pin, setPin] = useState({});
    const [loading, setLoading] = useState({ isLoading: false, message: "" });
    getUserInfo();

    function getUserInfo() {
        if(!data.username && !loading.isLoading){
            setLoading({ isLoading: true, message: "Loading your settings..."});

            apiFetch(`get-user-info?id=${user.id}&logged=${user.loggonToken}`, 0).then(res => {
                setData(res);
                setEmail(res.email);
                setPin(res.pin);
                setPinReq(res.pinRequired);
                setSendEmail([...res.viewers.map(v => v.email), ""]);
                setUsername(res.username);
            }).then(() => setLoading({ isLoading: false, message: ""}));
        }
    }

    function handleSave() {
        setLoading({ isLoading: true, message: "Saving changes..."});
        const viewers = emailToSend.filter(e => e).map(email => ({ email }))

        const req = { email, username, viewers, pinRequired, pin, password : document.querySelector("#new-pass").value };
        const goodPassword = validatePassword(req) || "";
        
        apiFetch(`update-user-info?id=${user.id}&logged=${user.loggonToken}${goodPassword}`, 4, {}, JSON.stringify(req))
        .then(() => setLoading({ isLoading: false, message: ""}));
    }

    function handleLogout() {
        clearLocalStorage();
        setLocalStorage("notify-data", { message : "Logged Out Successfully!", code : 200}, true);
        setLocalStorage("redirect", "/login", true, false);
    }

    return <>
        <Loading data={{loading: loading.isLoading, text: loading.message}} /> 
        {!!data.username && 
        <div>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" ></link>
            
            <EditUserInfo {...{email, setEmail, username, setUsername, isEditPage : true}} />

            <div className="edit-settings">
                <h3 className="edit-h3">Edit User Settings</h3>
                <div className="edit-user">

                    <EmailToSend {...{emailToSend, setSendEmail, isEditPage : true}} />
                    
                    <div className="settings-section">
                        <h4 className="edit-h4">Add Security Pin To Link</h4>
                        <input className="edit-input req-input" type="checkbox" onChange={(e) => setPinReq(e.target.checked)} checked={pinRequired} />
                        <hr className="edit-hr" />
                    </div>
                    <div className="settings-section">
                        <h4 className="edit-h4">Edit Security Pin</h4>
                        <input className="edit-input pin-input" type="text" onChange={(e) => setPin(e.target.value)} value={pin} />
                    </div>
                </div>
            </div>

            <UserInfpTable data={data} />

            <hr className="save-hr" />
                <span className="save-container">
                    <button className="logout-btn" onClick={handleLogout}><RiLogoutBoxLine /></button>
                    <button className="save-btn" onClick={() => document.location.reload()}>Reset</button>
                    <button className="save-btn" onClick={handleSave}>Save Changes</button>
                </span>
        </div>}
    </>
}