import React from 'react';
import { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLocalStorage } from '../../functions/getParamsAndCache';
import { capFirst } from '../../functions/numberFormat';

export function Notification(){

    useEffect(() => {
        const toastOptions = {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        };

        const Msg = ({ ...props }) => {
          console.log(props, capFirst(props.toastProps.type))
          return (
          <div className='popup-container'>
            <h5 className='popup-h5'>{props.header || capFirst(props.toastProps.type)}</h5>
            {props.message.split("$$$").length > 1 
            ? props.message.split("$$$").map((m) => <p className='popup-p'>&#8226; {m}</p>) 
            : <p className='popup-p one-popup'>{props.message}</p>
            }
          </div>
        )}

        function checkUserData() {
          let msg = localStorage.getItem('notify-data');
          console.log(msg)
          if (msg) {
            msg = JSON.parse(msg);

            if(msg.code < 200) toast.info(<Msg {...msg} />, toastOptions);
            else if(msg.code < 300) toast.success(<Msg {...msg} />, toastOptions);
            else if(msg.code < 400) toast.warn(<Msg {...msg} />, toastOptions);
            else toast.error(<Msg {...msg} />, toastOptions);
            setLocalStorage("notify-data", "", true, false);
          }
        }
      
        window.addEventListener('storage', checkUserData)
      
        return () => {
          window.removeEventListener('storage', checkUserData)
        }
      }, [])

  return (<ToastContainer
    position="top-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    rtl={false}
    draggable
    theme="dark"
    />
  );
}