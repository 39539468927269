export function Image({headers, imageObjectURL}) {
    return (
        <img key={headers.imageId}
             name={headers.disName} 
             flagged={headers.flag} 
             alt="shot" 
             className="img" 
             src={imageObjectURL} 
             heads={headers} 
             imageid={headers.imageId} 
             imgcount={headers.imgNumber} />
    )
}