import apiFetch from "../../functions/apiFetch";
import { getSearchParam, setLocalStorage } from "../../functions/getParamsAndCache";

export function Unsubscribe() {
    //id=42&email=srulylebo@gmail.com&key=9042AD4C 

    const handleUnsubscribe = () => {
        apiFetch(`unsubscribe-email-api?id=${getSearchParam("id")}&email=${getSearchParam("email")}&key=${getSearchParam("key")}`, 4)
        .then(console.log)
    }

    return (
    <div className="login-form">
        <h1 className="signup-h2">Warning!</h1>
        <br />
        <h2 className="home-body-h2">Are you sure you want to stop recieving emails?</h2>
        <p>you will not be able to get furure emails till the user will re-add you</p>
        <span>
            <button className="form-submit" onClick={() => setLocalStorage("redirect", "/", true)}>No</button>
            &nbsp;&nbsp;&nbsp;
            <button className="form-submit" onClick={handleUnsubscribe}>Yes</button>
        </span>
    </div>
    )
}