import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import { MyPictures } from "./Components/Image/MyPictures";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import { Unsubscribe } from "./Components/Settings/Unsubscribe";
import { Notification } from "./Components/Popup/Notification";
import { Redirect } from "./Components/Popup/Redirect";
import { Canvas } from "./Components/Animations/Canvas";
import { Home } from "./Components/Home/Home";
import { NavBar } from "./Components/Home/NavBar";
import { Login } from "./Components/Login/Login";
import { Signup } from "./Components/Login/Signup";
import { Settings } from "./Components/Settings/Settings";
import { ResetPassword } from "./Components/Login/ResetPassword";
import { ResetPasswordAction } from "./Components/Login/ResetPasswordAction";
import { SignupInfoCollection } from "./Components/Login/SignupInfoCollection";

function App() {
  
  return (
      <div className="app-container">
        <Canvas></Canvas>
        <Router>
          <NavBar />
          <div className="main-content">
            <Notification />
            <Redirect />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/my-pictures" element={<MyPictures />} />
                <Route exact path="/contact-us" element={<ContactUs />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/spider" element={<Canvas {...{controls: true}} />} />
                <Route exact path="/files/public" element={<MyPictures isPublic={true} />} />
                <Route exact path="/files/private" element={<MyPictures isPublic={false} />} />
                <Route exact path="unsubscribe-email" element={<Unsubscribe />} />
                <Route exact path="/reset-password" element={<ResetPassword />} />
                <Route exact path="/reset-password-action" element={<ResetPasswordAction />} />
                <Route exact path="/signup-info-collection" element={<SignupInfoCollection />} />
                {/* <Route exact path="/folders" element={<Folders />} />
                <Route exact path="/uncaught-error" element={<UncaughtError />} /> */}
            </Routes>
          </div>
        </Router>
      </div>
  );
}

export default App;
