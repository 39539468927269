import { useState } from "react";
import { useRef } from "react"
import apiFetch from "../../functions/apiFetch";

export function GetPin () {
    const routeId = new URLSearchParams(document.location.search).get("id");
    const inputRef = useRef();
    const [err, setErr] = useState("");
    
    async function handleClick() {
        let data = await apiFetch("varify-pin?id="+routeId+"&pin="+inputRef.current.value);
        if (data) {
            window.localStorage.setItem("tmpuser", data);
            document.location.reload();
        } else {
            setErr("Cannot varify pin, please try again later.")
        }
    }

    return <div className="pin-container">
        <h3 className="pin-h3">Enter Pin</h3>
        <p className="pin-p">You can find the pin in the email sent to you by our system.</p>
        <input ref={inputRef} type="text" />
        <button className="pin-btn" onClick={handleClick} type="button">Submit</button>
        <p>{err}</p>
    </div> 
}