import { useState } from "react"
import { Link } from "react-router-dom";

export function Signup() {
    const [deviceCount, setDeviceCount] = useState(1);
    const [emailCount, setEmailCount] = useState(1);

    return <div className="signup-container">
        <h1>Select a plan that fits your needs</h1>
        <div className="plan-card-container">
            <div className="plan-card">
                <h2 className="signup-h2">Personal Use</h2>
                <ul className="signup-ul">
                    <li>secure your sharing link with a pin</li>
                    <li>save screenshots for 2 weeks</li>
                    <li>add up to 5 devices</li>
                    <li>add up to 5 email addresses</li>
                </ul>
                <hr className="signup-hr" />
                <div className="signup-devices">
                    <h4>Select amount of devices needed</h4>
                    <div>
                        <button onClick={() => setDeviceCount(prev => prev <= 1 ? 1 : prev-1 )} className="signup-device-btn"><span className="minus-device-btn">---</span></button>
                        <span className="device-number">{deviceCount}</span>
                        <button onClick={() => setDeviceCount(prev => prev >= 5 ? 5 : prev+1)} className="signup-device-btn"><span className="plus-device-btn">+</span></button>
                    </div>
                </div>
                <hr className="signup-hr" />
                <Link to={`/pay-now?devices=${deviceCount}`}>
                    <button className="signup-card-btn">Sign Up</button>
                </Link>
            </div>
            <div className="plan-card">
                <h2 className="signup-h2">Commercial Use</h2><ul className="signup-ul">
                    <li>secure your sharing link with a pin</li>
                    <li>save screenshots for 2 weeks</li>
                    <li>unlimited devices</li>
                    <li>add up to 50 email addresses</li>
                </ul>
                <hr className="signup-hr" />
                <div className="signup-devices">
                    <h4>Select amount of emails needed</h4>
                    <div>
                        <button onClick={() => setEmailCount(prev => prev <= 1 ? 1 : prev-1 )} className="signup-device-btn"><span className="minus-device-btn">---</span></button>
                        <span className="device-number">{emailCount}</span>
                        <button onClick={() => setEmailCount(prev => prev >= 50 ? 50 : prev+1)} className="signup-device-btn"><span className="plus-device-btn">+</span></button>
                    </div>
                </div>
                <hr className="signup-hr" />
                <Link to={`/pay-now?devices=${deviceCount}`}>
                    <button disabled={true} className="signup-card-btn">Sign Up</button>
                </Link>
            </div>
        </div>
    </div>
}