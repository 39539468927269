export function EditUserInfo({email, setEmail, username, setUsername, isEditPage}) {
    return (
    <>
        <h3 className="edit-h3">{isEditPage && "Edit "}User Information</h3>
        <div className="edit-user">
            <div className="settings-section">
                <h4 className="edit-h4">{isEditPage && "Edit "}Username</h4>
                <input className="edit-input" type="text" onChange={(e) => setUsername(e.target.value)} value={username} />
                <label>username has to be at least 8 charecters</label>
                <hr className="edit-hr" />
            </div>
            <div className="settings-section">
                <h4 className="edit-h4">{isEditPage && "Edit "}Email</h4>
                <input className="edit-input" type="text" onChange={(e) => setEmail(e.target.value)} value={email} />
                <hr className="edit-hr" />
            </div>
            <div className="settings-section">
                <h4 className="edit-h4">{isEditPage ? "Change" : "Create"} Password</h4>
                {isEditPage && <input id="old-pass" className="edit-input edit-pass" type="password" placeholder="Old Password"/>}
                <input id="new-pass" className="edit-input edit-pass" type="password" placeholder={(isEditPage ? "New " : "") + "Password"}/>
                <input id="confirm-pass" className="edit-input edit-pass" type="password" placeholder={`Confirm ${isEditPage ? "New " : ""}Password`}/>
                <label>password has to be at least 8 charecters</label>
            </div>
        </div>
    </>
    )
}